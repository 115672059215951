import './App.css';

function App() {
  return (
    <div className="App">
      <video controls autoPlay muted>
        <source src="https://confindustria-meta-repo.adm-staging1.com/movies/V2_Intro%20YouTube.mp4" />
        Your browser does not support the video tag.
      </video>

    </div>
  );
}

export default App;
